var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
const Twitter = (_a) => {
    var { unique = '' } = _a, props = __rest(_a, ["unique"]);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24", role: "presentation", focusable: "false" }, props),
        React.createElement("path", { fill: "#000", d: "M15.187 15.985h-.85L8.791 7.962h.912z" }),
        React.createElement("path", { fill: "#000", fillRule: "evenodd", d: "M7.064 3h9.872C19.18 3 21 4.828 21 7.083v9.834C21 19.172 19.18 21 16.936 21H7.064C4.82 21 3 19.172 3 16.917V7.083C3 4.828 4.82 3 7.064 3m9.345 4h-1.533l-2.527 3.195L10.164 7H7l3.78 5.47L7.197 17h1.535l2.765-3.495L13.914 17H17l-3.94-5.764z", clipRule: "evenodd" })));
};
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
export default Twitter;
